/**
 * Custom hook that encapsulates the logic for saving a project. It handles the conditions under which a project
 * should be saved and manages the navigation state based on the saving outcome.
 *
 * @returns A function that, when called, executes the save logic based on the current app state and user actions.
 */
import { useHistory, useLocation } from 'react-router';
import { setIsSaving, setIsSavingSaveButton, useAppContext } from '../../../context/app-context';
import { useQueryParams } from '../../../hooks';
import { config } from '../../../regional-config';
import { savePersonalization } from '../../../utils/save-project';

export const useSaveProjectLogic = ({
  hasUpdatedDraftName,
  hasDefaultProjectName,
  saveDraftName,
  saveProject,
  initializedData,
  productQuantity,
  isEditorView,
}) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const { appDispatch } = useAppContext();

  const handleSaveLogic = async (): Promise<void> => {
    const isFeatureEnabled = config?.saveProjects?.hasProjectNameFeature;
    const shouldSaveProjectName = isFeatureEnabled && (hasUpdatedDraftName.current || !hasDefaultProjectName);
    const shouldUpdateDraftName =
      isFeatureEnabled && Number(productQuantity) !== initializedData?.purchase_quantity && initializedData?.name;

    try {
      setIsSavingSaveButton(appDispatch, true);
      setIsSaving(appDispatch, true);

      await Promise.all([
        savePersonalization(
          { shouldRestoreCanvas: true, showLoadingScreen: false, generateFrontPreview: false },
          isEditorView,
          saveProject,
        ),
        saveDraftName(initializedData.name || 'Draft', true),
      ]);

      if (localStorage.getItem('hasSavedProject') !== 'true') {
        localStorage.setItem('hasSavedProject', 'true');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving project:', error);
    } finally {
      setIsSavingSaveButton(appDispatch, false);
      setIsSaving(appDispatch, false);
    }

    const currentSearch = new URLSearchParams(window.location.search);
    if (!currentSearch.has('saveProjectDialog') && !shouldSaveProjectName && !shouldUpdateDraftName) {
      queryParams.append('saveProjectDialog', 'true');
      history.replace({ pathname, search: queryParams.toString() });
    }
  };

  return handleSaveLogic;
};
