import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogCE, DialogCEContentType, DialogCEFooterType } from '@hallmark/web.core.feedback.dialog-ce';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAppContext } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { DialogType } from '../../global-types/dialog';
import { config } from '../../regional-config';
import { ConfirmationDialogProps, DeleteConfirmationDialogProps } from './confirmation-dialog-types';

export const ConfirmationDialog = ({
  domId,
  title,
  actionButtonText,
  cancelButtonText,
  contentText,
  isOpen,
  onClose,
  onConfirm,
  useAlternativeDialog,
}: ConfirmationDialogProps): React.ReactElement => {
  const { t } = useTranslation();

  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  const actionButton = (
    <Button
      click={() => onConfirm()}
      mode={config?.dialog?.applyButtonMode || ButtonModes.Primary}
      typographyVariant={config?.dialog?.applyTypographyVariant || TypographyVariants.Body}
      testId={'confirmation-dialog-action-button'}
    >
      {actionButtonText}
    </Button>
  );

  const cancelButton = (
    <Button
      mode={config?.dialog?.cancelButtonMode || ButtonModes.TextLink}
      click={() => onClose()}
      testId={'confirmation-dialog-cancel-button'}
      typographyVariant={config?.dialog?.cancelTypographyVariant || TypographyVariants.Body}
    >
      {cancelButtonText ? cancelButtonText : t('confirmationDialog.cancelButton')}
    </Button>
  );

  if (useAlternativeDialog) {
    return (
      <DialogCE
        id={domId}
        testId={'confirmation-dialog'}
        hasGrayBackground={false}
        type={DialogCEContentType.Modal}
        isOpen={isOpen}
        onClose={onClose}
        actionButton={actionButton}
        cancelButton={cancelButton}
        title={title}
        footerType={DialogCEFooterType.Cancel}
        closeButtonId={`${domId}-close-button`}
      >
        {contentText || ''}
      </DialogCE>
    );
  } else {
    return (
      <Dialog
        id={domId}
        testId={'confirmation-dialog'}
        accentIcon={IconNames.CardFeaturesBold}
        accentIconColor={BrandColors.White}
        hasGrayBackground={false}
        type={DialogContentType.Modal}
        isOpen={isOpen}
        onClose={onClose}
        actionButton={actionButton}
        cancelButton={cancelButton}
        title={title}
        footerType={DialogFooterType.Cancel}
        closeButtonId={`${domId}-close-button`}
        locale={isUK ? 'uk' : 'us'}
      >
        {contentText || ''}
      </Dialog>
    );
  }
};

export const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  const { appState } = useAppContext();
  const { dialogType } = appState;
  const { t } = useTranslation();

  const getTitleBasedOnDialogType = () => {
    switch (dialogType) {
      case DialogType.Image:
        return t('deleteConfirmationDialogTitle.image');
      case DialogType.Text:
        return t('deleteConfirmationDialogTitle.text');
      case DialogType.Sticker:
        return t('deleteConfirmationDialogTitle.sticker');
      default:
        return t('deleteConfirmationDialogTitle.default');
    }
  };

  const getSubtitleBasedOnDialogType = () => {
    switch (dialogType) {
      case DialogType.Image:
        return t('deleteConfirmationDialogSubtitle.image');
      case DialogType.Text:
        return t('deleteConfirmationDialogSubtitle.text');
      case DialogType.Sticker:
        return t('deleteConfirmationDialogSubtitle.sticker');
      default:
        return t('deleteConfirmationDialogSubtitle.default');
    }
  };

  const title = getTitleBasedOnDialogType();
  const subtitle = getSubtitleBasedOnDialogType();

  return (
    <ConfirmationDialog
      domId="delete-confirmation-dialog"
      title={title}
      contentText={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      actionButtonText={t('confirmationDialog.actionButton')}
      useAlternativeDialog={config?.dialog?.useAlternativeDialog || false}
    />
  );
};

export const ResetConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  const contentText = "If this image isn't already saved in your photo library, you'll lose it permanently.";

  return (
    <ConfirmationDialog
      domId="reset-confirmation-dialog"
      title="Reset this image?"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      contentText={contentText}
      actionButtonText="Reset"
      useAlternativeDialog={config?.dialog?.useAlternativeDialog || false}
    />
  );
};

export const ClearConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  return (
    <ConfirmationDialog
      domId="clear-confirmation-dialog"
      title="Are you sure you want to clear this content?"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      actionButtonText="Clear"
      useAlternativeDialog={config?.dialog?.useAlternativeDialog || false}
    />
  );
};
