import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { AbandonButtonProps, CancelButtonProps, SubmitButtonProps } from '../save-project-dialog-types';
import styles from '../save-project-dialog.module.scss';

export const CancelButton = ({ onCancel }: CancelButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button click={onCancel} mode={ButtonModes.TextLink} addClass={styles['footer-button']}>
      {t('saveProjectDialog.cancelButton')}
    </Button>
  );
};

export const SubmitButton = ({ onSaveProject, isLoggedIn, onSignIn }: SubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      mode={ButtonModes.Primary}
      click={isLoggedIn ? onSaveProject : onSignIn}
      type={ButtonTypes.Submit}
      addClass={styles['footer-button']}
    >
      {isLoggedIn ? t('saveProjectDialog.saveProjectButton') : t('saveProjectDialog.signInButton')}
    </Button>
  );
};

export const AbandonProjectButton = ({ onAbandon }: AbandonButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button click={onAbandon} mode={ButtonModes.Secondary} addClass={styles['footer-button']}>
      {t('saveProjectDialog.abandonButton')}
    </Button>
  );
};
