import React, { ReactElement, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { SEO } from '@hallmark/web.page-components.seo';
import AddressConfirmation from '../../components/address-confirmation/address-confirmation';
import { AddressForm } from '../../components/address-form/address-form';
import { EmailAddressForm } from '../../components/email-address-form/email-address-form';
import { Layout } from '../../components/layout';
import { useAnalyticsContext } from '../../context/analytics-context';
import { hideLoadingScreen, showLoadingScreen, useAppContext } from '../../context/app-context';
import { useCardContext } from '../../context/card-context';
import { cleanAddresses, updateAddresses, updateDigitalAddress } from '../../context/data-context';
import { useInitializationDataContext } from '../../context/data-context';
import {
  AddressForm as AddressFormData,
  FormValidation,
  Address,
  AddressResponseData,
  SaveAddressResponse,
  AddressTypes,
  ErrorType,
  ErrorResponse,
  CardType,
  EmailAddressForm as EmailAddressFormData,
  DigitalAddress,
} from '../../global-types';
import { useFeatureFlags, useIsOneToMany, useLineItemUUID, useSystemErrorHandling } from '../../hooks';
import { useHandleIndexChange } from '../../hooks/useHandleIndexChange';
import { saveAddress, getAddresses, updateAddress, saveRecipient } from '../../services';
import { deleteAddress } from '../../services/address';
import {
  getAddressError,
  getFieldsFromAddressResponse,
  mapAddressesByType,
  isLoggedIn,
  loginRedirect,
  getCookie,
} from '../../utils';
import { useLoadExistingProject } from '../editor/hooks';
import { SubmittedAddresses } from './address-types';
import { defaultFormValues } from './address.data';
import styles from './address.module.scss';
import { AddressEnvelope, AddressFooter } from './fragments';
import { EnvelopeSelection } from './fragments/envelope-selection';
import { useAddressBook } from './hooks/useAddressBook';
import { useAddressConfirmationDialog } from './hooks/useAddressConfirmationDialog';
import { useEnvelopeAddresses } from './hooks/useEnvelopeAddresses';
import { useLoadCardAfterLogin } from './hooks/useLoadCardAfterLogin';
import { useMigrateAndLoadProject } from './hooks/useMigrateAndLoadProject';
import { populatePreviewImages } from './utils/populate-preview-images';

/** @todo enable this when envelope is done */
const SHOW_ENVELOPE = false;

export const AddressView = (): ReactElement => {
  // State variables
  const [step, setStep] = useState(0);
  const [validAddresses, setValidAddresses] = useState(false);
  const [submittedAddresses, setSubmittedAddresses] = useState<SubmittedAddresses>({ recipient: null, sender: null });
  const [shouldSave, setShouldSave] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [preloadVerified, setPreloadVerified] = useState(false);
  const { appDispatch } = useAppContext();
  // Routing
  const history = useHistory();
  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const lastProjectLoaded = sessionStorage.getItem('lastProjectLoaded');
  const isDataLoaded = useRef<boolean>(false);
  const isDigitalGreeting = projectTypeCode === CardType.DG;
  const { search } = useLocation();
  // Contexts
  const { cardDispatch } = useCardContext();
  const { appState } = useAppContext();
  const { previewImages } = appState;
  const {
    initializedDataState: { data: initializedData, lineItemUUID, addressData, savedContacts, isUK, isUS },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const handleIndexChange = useHandleIndexChange();
  const isOneToMany = useIsOneToMany();
  const loadExistingProject = useLoadExistingProject();
  const { trackClickNextToAddressSender, trackReturnToEdit } = useAnalyticsContext();
  // Translation
  const { t } = useTranslation();
  // Error handler to show dialog when error is catch
  const [onSystemError] = useSystemErrorHandling();
  // Get the addresses to fill based on country and envelope selection
  const addressTypesToFill = useEnvelopeAddresses();
  const addressType = addressTypesToFill[+step];

  // Migrate and load the migrated project after the user logs in and store it in data-context
  useMigrateAndLoadProject();
  // After login personalizationData (used for preview) is empty
  // Populates cardState and personalizationData with the loaded project
  useLoadCardAfterLogin();
  // Preserve lineItemUUID on query string and data-context
  useLineItemUUID();
  // * Address Confirmation Dialog
  const {
    addAddressToValidate,
    closeAddressConfirmationDialog,
    addressesToValidate,
    openAddressConfirmationDialog,
    isConfirmationDialogOpen,
  } = useAddressConfirmationDialog();
  // Function to be triggered when all the addresses where confirmed in address confirmation dialog
  const onSkipValidation = useCallback(
    (addressesToValidate: FormValidation[]) => {
      const skippedValidationAddresses = addressesToValidate
        .map((address) => {
          const { addressType } = address;
          const skippedAddress = submittedAddresses[`${addressType}`];
          return skippedAddress ? { ...skippedAddress, skip_usps_validation: true } : null;
        })
        .filter((address) => address !== null) as AddressFormData[];

      setSubmittedAddresses((addresses) => ({
        ...addresses,
        ...mapAddressesByType<AddressFormData>(skippedValidationAddresses),
      }));
      closeAddressConfirmationDialog();
      setShouldSave(true);
    },
    [closeAddressConfirmationDialog, submittedAddresses],
  );
  // Function to be triggered when user wants to edit one of the addresses from address confirmation dialog
  const onEditAddress = useCallback(
    (stepToEdit: number) => {
      setStep(stepToEdit);
      closeAddressConfirmationDialog();
    },
    [closeAddressConfirmationDialog],
  );

  const loadProject = async () => {
    showLoadingScreen(appDispatch, '');
    await loadExistingProject(lastProjectLoaded || '', isDataLoaded);
    hideLoadingScreen(appDispatch);
  };

  // Set the default address values to pre-populate the address form (if an address had been stored to the project)
  // Sets the country_code, address_type_code, and skip_usps_validation to the corresponding values
  const addressFormDefaultData = useMemo(() => {
    const currentAddressType = addressTypesToFill[+step];
    const storedAddress = getFieldsFromAddressResponse(addressData[`${currentAddressType}`]);
    const searchParams = new URLSearchParams(search);
    const envelope = searchParams.get('envelope') as AddressFormData['envelope'];
    const defaultAddressOptions: Partial<AddressFormData> = {
      country_code: isUK ? 'UK' : 'USA',
      skip_usps_validation: isUK ? true : false,
      address_type_code: currentAddressType === AddressTypes.RECIPIENT ? 'R' : 'S',
      envelope: envelope || null,
    };
    return { ...storedAddress, ...defaultAddressOptions };
  }, [step, addressData.recipient, addressData.sender, addressTypesToFill, isUK]);

  const formValues = useForm<AddressFormData>({
    mode: 'onBlur',
    defaultValues: addressFormDefaultData as AddressFormData,
  });
  const {
    handleSubmit: handleAddressFormSubmit,
    formState: { isValid: isAddressFormValid },
    reset,
    getValues,
    watch,
  } = formValues;
  const { ENVELOPE_SELECTION, GENERATE_FE_PREVIEWS } = useFeatureFlags();

  const envelope = ENVELOPE_SELECTION ? watch('envelope') : null;

  const shouldValidateForm = ENVELOPE_SELECTION ? envelope !== 'blank' : true;

  useEffect(() => {
    const defaultValues = { ...defaultFormValues, ...addressFormDefaultData };
    reset(defaultValues);
  }, [addressFormDefaultData]);

  const submitLabel = useMemo(() => {
    // Show preview step when on last addressing step, or when card is DG or One:Many, which have only one step.
    const shouldMoveToPreview = addressTypesToFill.length === step + 1 || isDigitalGreeting || isOneToMany;
    return shouldMoveToPreview ? t('addressForm.preview') : t('addressForm.continue');
  }, [step, addressTypesToFill, t]);

  // Address Book: Loads address-book if the user is logged in and returns a function to save a contact
  const { saveContact } = useAddressBook();

  // Load local project data. Attempts to use data from initialize first
  // falls back to saved session data
  const getLocalProjectData = () => {
    if (initializedData !== null) {
      return {
        project_type_code: initializedData.project_type_code,
        account_id: initializedData.account_id,
        project_id: initializedData.project_id,
        lineItemUUID,
      };
    }

    return {
      project_type_code: sessionStorage.getItem('lastProjectTypeCode'),
      account_id: getCookie('accountId'),
      project_id: lastProjectLoaded,
      lineItemUUID,
    };
  };

  useEffect(() => {
    const projectData = getLocalProjectData();
    if (projectData.project_type_code === CardType.DG && !isLoggedIn()) {
      // Redirect if we're editing a DG and the user is not logged in
      loginRedirect(projectData.project_id as string, projectData.account_id as string, projectData.lineItemUUID, isUK);
    } else {
      // Show address form if logged in already
      setPreloadVerified(true);
    }
    if (initializedData === null) {
      loadProject();
    } else {
      // If initilization data exists, check if we have preview images stored
      // If we don't, user reloaded. Populate state with preview images in assets data
      if (
        previewImages.length === 0 &&
        GENERATE_FE_PREVIEWS &&
        initializedData?.assets &&
        initializedData.assets.length > 0
      ) {
        populatePreviewImages(initializedData, appDispatch, cardDispatch);
      }
    }
  }, [initializedData]);
  /**
   * Loops through saved addresses and called delete address endpoint for each address,  There's no
   * use case for this now, but will be if we decide to select a blank envelope on 1:1 cards
   */
  const removeSavedAddresses = (projectId: string, addresses: AddressResponseData[]) => {
    const addressIds = addresses.map((address) => address.address_id);
    for (let i = 0; i < addressIds.length; i++) {
      deleteAddress(projectId, addressIds[`${i}`]);
    }
  };
  const skipFormValidation = () => {
    const addresses = [submittedAddresses.recipient, submittedAddresses.sender].filter(
      (element) => element !== null,
    ) as unknown as AddressResponseData[];

    if (envelope === 'blank') {
      cleanAddresses(initializationDataDispatch);
    }

    if (addresses && addresses.length <= 0) {
      // Delete address from backend
      removeSavedAddresses(initializedData?.project_id as string, addresses);
      // Remove address from state
      cleanAddresses(initializationDataDispatch);
    }
    const searchParams = new URLSearchParams(search);
    if (envelope) {
      searchParams.set('envelope', envelope);
    }
    history.push({ pathname: '/card/customization/preview', search: searchParams.toString() });
    return;
  };

  // * Address Form
  const handleAddressSubmit = (data: AddressFormData) => {
    // remove send_to key from data or it'll cause addresses endpoint to 400
    delete data?.send_to;
    delete data?.envelope;
    const totalSteps = addressTypesToFill.length;
    const currentAddressType = data.address_type_code === 'R' ? AddressTypes.RECIPIENT : AddressTypes.SENDER;

    // update state by saving the submitted address
    updateAddresses(initializationDataDispatch, {
      [currentAddressType]: { ...addressData[`${currentAddressType}`], ...(data as Address) },
    });
    const submittedAddress = mapAddressesByType([{ ...data }]);
    setSubmittedAddresses((addresses) => ({ ...addresses, ...submittedAddress }));

    // move to the next step or start address validation
    if (step < totalSteps - 1) {
      setStep((currentStep) => currentStep + 1);
      trackClickNextToAddressSender();
      return;
    }
    setShouldSave(true);
  };

  const handleEmailSubmit = async (data: EmailAddressFormData) => {
    const projectId = initializedData?.project_id;

    data.recipient_type_code = 'E';
    // update state by saving the submitted address
    updateDigitalAddress(initializationDataDispatch, data);

    if (!projectId) return;

    if (data) {
      setShouldSave(true);
    }

    try {
      const recipientResponse = await saveRecipient(projectId, data);
      const recipientDataReponse: any = recipientResponse.data;

      const { recipient_id } = recipientDataReponse;

      const recipientIdToStore = recipient_id || '';
      sessionStorage.setItem('recipient_id', recipientIdToStore);
    } catch (errors) {
      onSystemError(errors as ErrorResponse);
    }
  };

  const handleSaveAddress = async (projectId: string, data: Omit<AddressFormData, 'isQuickAddress'>, step: number) => {
    const { address_type_code, ...address } = data;
    GENERATE_FE_PREVIEWS && showLoadingScreen(appDispatch, 'Generating preview');
    setIsAddressLoading(true);
    const addressId =
      address_type_code === 'R'
        ? (addressData.recipient as AddressResponseData)?.address_id
        : (addressData.sender as AddressResponseData)?.address_id;
    try {
      const response: SaveAddressResponse = addressId
        ? await updateAddress(projectId, addressId, address)
        : await saveAddress(projectId, data);
      if (response.data) {
        const mappedAddress = mapAddressesByType([response.data]);
        updateAddresses(initializationDataDispatch, mappedAddress);
      }
    } catch (error) {
      if ((error as ErrorResponse)?.status === 422) {
        const addressError = getAddressError((error as ErrorResponse).data.errors);
        addAddressToValidate(data, addressError?.description as ErrorType, step);
        openAddressConfirmationDialog();
        throw error;
      } else {
        setIsAddressLoading(false);
      }
      hideLoadingScreen(appDispatch);
      onSystemError(error as ErrorResponse);
    }
  };

  const goBack = (addressFormValues: AddressFormData, emailAddressFormValues: EmailAddressFormData) => {
    if (isDigitalGreeting) {
      const currentEmailAddress = { ...addressData.digital, ...emailAddressFormValues };
      updateDigitalAddress(initializationDataDispatch, currentEmailAddress);
    } else {
      const currentAddressType = addressTypesToFill[+step];
      const currentAddress = { ...addressData[`${currentAddressType}`], ...addressFormValues };
      updateAddresses(initializationDataDispatch, { [currentAddressType]: currentAddress });
    }

    if (step > 0) {
      return setStep((currentStep) => currentStep - 1);
    }
    trackReturnToEdit();
    const searchParams = new URLSearchParams(search);

    if (ENVELOPE_SELECTION && envelope) {
      searchParams.set('envelope', envelope);
    }

    const lastProjectLoaded = sessionStorage.getItem('lastProjectLoaded');
    const editorUrl = lastProjectLoaded
      ? `/card/customization/edit/${lastProjectLoaded}`
      : `/card/customization/edit/${initializedData?.project_id}`;

    history.push({ pathname: editorUrl, search: searchParams.toString() });
    handleIndexChange(0, 0);
  };

  // Get the stored addresses for the current project and store it in data-context
  useEffect(() => {
    const { recipient, sender } = addressData;
    const projectId = initializedData?.project_id;
    if (recipient || sender || !projectId) {
      return;
    }
    getAddresses(projectId)
      .then((response) => {
        if (response.data) {
          const addresses = mapAddressesByType<AddressResponseData>(response.data);
          updateAddresses(initializationDataDispatch, addresses);
        }
      })
      .catch((error) => onSystemError(error));
  }, [addressData, initializedData?.project_id]);

  // After both forms are submitted (or skipped validation in confirmation dialog if needed) and stored in the state variable submittedAddresses
  // we want to get that info and call the addresses API to store the submitted addresses in the project
  useEffect(() => {
    const projectId = initializedData?.project_id;
    if (!shouldSave || !projectId) {
      return;
    }

    if (shouldSave && isDigitalGreeting) {
      setValidAddresses(true);
      return;
    }

    const addressesToSave = addressTypesToFill.map(
      (addressType) => submittedAddresses[`${addressType}`],
    ) as AddressFormData[];

    let remainingContactsToSave = addressesToSave.reduce(
      (count, address) => (address.isQuickAddress ? count + 1 : count),
      0,
    );
    const allowedContactsToSave = 10 - savedContacts.length;
    const savingAddresses = addressesToSave.map(async (address, index, addresses) => {
      const { isQuickAddress, ...addressToSave } = address as AddressFormData;
      const shouldSkipNextContact =
        isQuickAddress && allowedContactsToSave > 0 && allowedContactsToSave - remainingContactsToSave < 0;
      if (shouldSkipNextContact) {
        addresses[index + 1].isQuickAddress = false;
        remainingContactsToSave--;
      }
      await handleSaveAddress(projectId, addressToSave, index);
      if (isQuickAddress) {
        saveContact(addressToSave, shouldSkipNextContact);
      }
    });

    Promise.all(savingAddresses)
      .then(() => {
        setValidAddresses(true);
      })
      .catch(() => {
        setValidAddresses(false);
      })
      .finally(() => {
        setShouldSave(false);
      });
  }, [shouldSave, initializedData?.project_id, submittedAddresses, saveContact]);

  // After all addresses were successfully sent to the BE, then we want to push the user to the preview view
  useEffect(() => {
    if (validAddresses) {
      // store envelope selection in the url
      const searchParams = new URLSearchParams(search);
      if (envelope) {
        searchParams.set('envelope', envelope);
      }
      history.push({ pathname: '/card/customization/preview', search: searchParams.toString() });
    }
  }, [validAddresses]);

  // Set the default address values to pre-populate the email form (if an address had been stored to the project)
  const emailFormDefaultData = useMemo(() => {
    const storedAddress = getFieldsFromAddressResponse(addressData[AddressTypes.DIGITAL] as DigitalAddress);
    return { ...storedAddress };
  }, [addressData.digital]);

  const emailValues = useForm<EmailAddressFormData>({
    mode: 'onBlur',
    defaultValues: emailFormDefaultData,
  });

  // When dialog is closed, we want enable the preview button.
  useEffect(() => {
    if (!isConfirmationDialogOpen) {
      setIsAddressLoading(false);
    }
  }, [isConfirmationDialogOpen]);

  const {
    handleSubmit: handleEmailFormSubmit,
    formState: { isValid: isValidEmail },
    getValues: getValuesEmailForm,
  } = emailValues;

  const onHandleGoBack = () => {
    const emailFormValues = getValuesEmailForm();
    const addressFormValues = getValues();
    goBack(addressFormValues, emailFormValues);
  };

  const formIsValid = useMemo(() => {
    if (isDigitalGreeting) {
      return isValidEmail;
    }

    return isAddressFormValid || !shouldValidateForm;
  }, [isDigitalGreeting, isAddressFormValid, isValidEmail, shouldValidateForm]);

  const handleSubmit = () => {
    if (isDigitalGreeting) {
      return handleEmailFormSubmit(handleEmailSubmit);
    }
    return shouldValidateForm ? handleAddressFormSubmit(handleAddressSubmit) : skipFormValidation;
  };

  return (
    <Layout hideToolbar toastId="toast">
      <SEO title={`${t('addressView.title')}`} description={`${t('addressView.seo')}`} />
      <div className={styles.address} data-testid="address-view">
        <div className={classNames(styles.content, isUS && SHOW_ENVELOPE && styles['show-envelope-preview'])}>
          {SHOW_ENVELOPE && preloadVerified && isUS && <AddressEnvelope form={formValues} addressType={addressType} />}
          {ENVELOPE_SELECTION && <EnvelopeSelection formHandlers={formValues} />}
          {isDigitalGreeting ? (
            <EmailAddressForm formHandlers={emailValues} />
          ) : (
            preloadVerified && (
              <AddressForm
                shouldRenderEnvelope={ENVELOPE_SELECTION}
                addressType={addressType}
                formHandlers={formValues}
              />
            )
          )}
        </div>
        <AddressFooter
          isValid={formIsValid}
          isAddressLoading={isAddressLoading}
          goBack={onHandleGoBack}
          submitLabel={submitLabel}
          handleSubmit={handleSubmit()}
        />
      </div>
      {isConfirmationDialogOpen && (
        <AddressConfirmation
          isOpen={isConfirmationDialogOpen}
          close={closeAddressConfirmationDialog}
          addressesToValidate={addressesToValidate}
          onSkipValidation={onSkipValidation}
          onEditAddress={onEditAddress}
        />
      )}
    </Layout>
  );
};
