import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/card-context';
import { CanvasDataTypes } from '../../../utils';

export const useCardZoneValidation = () => {
  const { t } = useTranslation();
  const {
    cardState: { cardFacesList },
  } = useCardContext();

  const checkMandatoryTextFields = (face) => {
    const textObjects = face.canvas.current._objects.filter(
      (obj) => obj.type === 'textbox' && obj.isModified !== undefined,
    );

    return textObjects.every((obj) => obj.isModified);
  };

  const checkMandatoryPhotoZones = (face) => {
    const photoZoneObjects = face.canvas.current._objects.filter(
      (obj) => obj.name && obj.name.startsWith('photozone-') && obj.data?.hasContent !== undefined,
    );

    const allZonesFilled = photoZoneObjects.every((zone) => zone.data.hasContent === true);

    return allZonesFilled;
  };

  const checkEditableAreas = (face) => {
    if (!face.editableAreas || face.editableAreas.length === 0) {
      return true;
    }

    const editableAreaObjects = face.canvas.current._objects.filter((obj) => obj.name?.startsWith('area-'));

    return editableAreaObjects.every((obj) => obj.isModified);
  };

  const hasRelevantObjects = (face) => {
    const relevantTypes = [
      CanvasDataTypes.UserText,
      CanvasDataTypes.StickerImage,
      CanvasDataTypes.UserImage,
      CanvasDataTypes.Placeholder,
    ];

    return face.canvas.current._objects.some((obj) => relevantTypes.includes(obj?.data?.type));
  };

  const hasUneditedPlaceholderText = (face) => {
    const placeholderObjects = face.canvas.current._objects.filter(
      (obj) =>
        obj.type === 'textbox' && obj.data?.type === 'placeholder' && obj.text === 'Dubbelklik om tekst toe te voegen',
    );

    return placeholderObjects.length > 0;
  };

  const isFaceEdited = (face) => {
    if (!face || !face.canvas || !face.canvas.current || !face.canvas.current._objects) {
      return false;
    }

    const textFieldsEdited = checkMandatoryTextFields(face);
    const photoZonesFilled = checkMandatoryPhotoZones(face);
    const editableAreasEdited = checkEditableAreas(face);

    return textFieldsEdited && photoZonesFilled && editableAreasEdited && !hasUneditedPlaceholderText(face);
  };

  const validateZones = useCallback(
    (userAttemptedProceed) => {
      const frontFace = cardFacesList.find((face) => face.type === 'front');
      const insideFace = cardFacesList.find((face) => face.type === 'inside');

      const isFrontEdited = frontFace ? isFaceEdited(frontFace) : true;
      const isInsideEdited = insideFace ? isFaceEdited(insideFace) : false;
      const hasInsideObjects = insideFace ? hasRelevantObjects(insideFace) : false;

      if (!isFrontEdited && !isInsideEdited) {
        return {
          canProceed: false,
          showPopup: false,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (!isFrontEdited && isInsideEdited) {
        return {
          canProceed: false,
          showPopup: userAttemptedProceed,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && (!isInsideEdited || !hasInsideObjects)) {
        return {
          canProceed: userAttemptedProceed,
          showPopup: !userAttemptedProceed,
          showToast: false,
          toastMessage: {},
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && isInsideEdited) {
        return {
          canProceed: true,
          showPopup: true,
          showToast: false,
          popupMessage: {
            title: t('zoneValidation.ready.title'),
            description: t('zoneValidation.ready.description'),
          },
        };
      } else {
        return {
          canProceed: false,
          showPopup: false,
          toastMessage: {
            title: t('zoneValidation.error.title'),
            description: t('zoneValidation.error.description'),
          },
        };
      }
    },
    [cardFacesList],
  );

  return { validateZones };
};

export default useCardZoneValidation;
