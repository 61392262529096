import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSaveProjectDialog } from '../../hooks';
import { config } from '../../regional-config';
import CEHeaderLayout from './fragments/ce-header-layout';
import USHeaderLayout from './fragments/us-header-layout';
import { HeaderVariants, PrintOnDemandHeaderProps } from './print-on-demand-header-types';
import styles from './print-on-demand-header.module.scss';

const PrintOnDemandHeader = ({ onClose, isEditor, onReturnToHomePage }: PrintOnDemandHeaderProps) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const classes = classNames(styles.header, isEditor && styles.editor);

  const { setIsExitFromXButton } = useSaveProjectDialog();

  // Shared logic for logo icon click
  const onLogoIconClick = () => {
    setIsExitFromXButton(false);
    onReturnToHomePage();
  };

  // Shared logic for close button click
  const onCloseButtonClick = () => {
    setIsExitFromXButton(true);
    onClose(closeButtonRef.current);
  };

  const renderHeaderLayout = () => {
    if (config?.header?.variant === HeaderVariants.CE) {
      return (
        <CEHeaderLayout
          closeButtonRef={closeButtonRef}
          onCloseButtonClick={onCloseButtonClick}
          onLogoIconClick={onLogoIconClick}
          classes={classes}
        />
      );
    } else {
      return (
        <USHeaderLayout
          onCloseButtonClick={onCloseButtonClick}
          onCrownIconClick={onLogoIconClick}
          classes={classes}
          closeButtonRef={closeButtonRef}
        />
      );
    }
  };

  return renderHeaderLayout();
};

const MemoizedPrintOnDemandHeader = React.memo(PrintOnDemandHeader);

export { MemoizedPrintOnDemandHeader as PrintOnDemandHeader };
