import { RefObject } from 'react';
import { isMobile } from 'react-device-detect';
import { fabric } from 'fabric';
import { CanvasDataTypes, CustomFabricType } from '../canvas';

export const createFabricImageInstance = (
  imageRef: RefObject<HTMLImageElement>,
  canvasDataType: CanvasDataTypes.UserImage | CanvasDataTypes.StickerImage,
  imageName: string | undefined,
  borderColor: string,
  currentCanvas: fabric.Canvas,
  customType?: CustomFabricType,
  activeCardIndex?: number,
): fabric.Image => {
  const canvasWidth = currentCanvas.getWidth();
  const canvasHeight = currentCanvas.getHeight();
  const zoomLevel = currentCanvas.getZoom();

  const imageElement = imageRef.current;
  if (!imageElement) throw new Error('Image element is missing.');

  const fabricImage = new fabric.Image(imageElement, {
    name: imageName,
    borderColor,
    crossOrigin: 'anonymous',
    hasControls: true,
    data: {
      type: canvasDataType,
      customType,
    },
    padding: 10,
  });

  const scaleWidth = isMobile ? canvasWidth * 0.33 : 150;

  fabricImage.scaleToWidth(scaleWidth);

  const leftPositionMobile = activeCardIndex === 1 ? 900 : 20 / zoomLevel;

  const leftPositionDesktop =
    activeCardIndex === 1 ? (canvasWidth / 2 + fabricImage.getScaledWidth() + 20) / zoomLevel : 20 / zoomLevel;

  const leftPosition = isMobile ? leftPositionMobile : leftPositionDesktop;

  const topPosition = (canvasHeight - fabricImage.getScaledHeight()) / 2 / zoomLevel;

  fabricImage.set({
    left: leftPosition,
    top: topPosition,
  });

  currentCanvas.add(fabricImage);
  currentCanvas.renderAll();

  return fabricImage;
};
