import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors, DialogContentType } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { SignInForm, SignInFormProps, signInFormInputs } from '@hallmark/web.page-components.sign-in-form';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useProjectSummary } from '../../hooks';
import { AbandonProjectButton, SubmitButton, CancelButton } from '../save-project-dialog/fragments/footer-buttons';
import { AuthenticatedFormData, DialogFooter, SignInFormData } from '../save-project-dialog/save-project-dialog-types';
import styles from './sign-in-dialog.module.scss';
import { SignInDialogProps } from './sign-in-dialog.types';

export const SignInDialog = ({
  close,
  isLoggedIn,
  isOpen,
  isAbandoningProject,
  onSaveProject,
  onSignIn,
  onAbandonProject,
  theme,
}: SignInDialogProps) => {
  const { t } = useTranslation();
  const { defaultProjectName } = useProjectSummary();

  // Get dialog title and description
  const unauthenticatedTitle = isAbandoningProject ? t('signInDialog.abandonTitle') : t('signInDialog.signInTitle');

  const title = isLoggedIn ? t('signInDialog.saveProjectTitle') : unauthenticatedTitle;

  const saveProjectForm = useForm<AuthenticatedFormData>({
    values: { projectName: defaultProjectName },
    mode: 'onBlur',
  });

  const { handleSubmit: handleSaveProjectSubmit } = saveProjectForm;

  const testOnSubmit = (data: signInFormInputs) => {
    // eslint-disable-next-line no-console
    console.log('save project action data: ', data);
  };

  const dialogFooterProps: DialogFooter = {
    footerType: isAbandoningProject ? DialogFooterType.MultipleButtons : DialogFooterType.Cancel,
    actionButton: (
      <SubmitButton
        isLoggedIn={isLoggedIn}
        onSaveProject={handleSaveProjectSubmit(onSaveProject)}
        onSignIn={onSignIn}
      />
    ),
  };

  if (isAbandoningProject) {
    dialogFooterProps.secondaryActionButton = <AbandonProjectButton onAbandon={onAbandonProject} />;
  } else {
    dialogFooterProps.cancelButton = <CancelButton onCancel={close} />;
  }

  // TODO: Not finished yet, will be in the future task
  const accountUrl = process.env.REACT_APP_ACCOUNT_URL;

  const signInForm = useForm<SignInFormData>({ mode: 'onBlur' });
  const signInFormProps: SignInFormProps = {
    emailLabel: t('signInDialog.emailLabel'),
    passwordLabel: t('signInDialog.passwordLabel'),
    forgotPasswordLabel: t('signInDialog.forgotPasswordLabel'),
    registerLinkLabel: t('signInDialog.registerLinkLabel'),
    noAccountLabel: t('signInDialog.noAccountLabel'),
    invalidEmailText: t('signInDialog.invalidEmailText'),
    invalidPasswordText: t('signInDialog.invalidPasswordText'),
    requiredEmailText: t('signInDialog.requiredEmailText'),
    requiredPasswordText: t('signInDialog.requiredPasswordText'),
    formMethods: signInForm,
    forgotPasswordLink: `${accountUrl}/#/forgotpassword`,
    registerLink: `${accountUrl}/#/signup`,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={title}
      accentIcon={IconNames.CardFront}
      accentIconColor={DialogBrandColors.White}
      type={DialogContentType.FeatureModal}
      addDialogWrapperClass={styles['dialog-wrapper']}
      footerType={dialogFooterProps.footerType}
      cancelButton={dialogFooterProps.cancelButton}
      actionButton={dialogFooterProps.actionButton}
      secondaryActionButton={dialogFooterProps.secondaryActionButton}
      locale={theme}
    >
      <form>
        <SignInForm {...signInFormProps} />
        <Button type={ButtonTypes.Submit} click={signInForm.handleSubmit(testOnSubmit)}>
          Submit
        </Button>
      </form>
    </Dialog>
  );
};
