import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { AuthenticatedFormProps } from '../save-project-dialog-types';
import styles from '../save-project-dialog.module.scss';

export const AuthenticatedForm = ({ formMethods: { register }, onSubmit }: AuthenticatedFormProps) => {
  const { t } = useTranslation();

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <TextField
        register={register('projectName', { required: `${t('saveProjectDialog.requiredProjectName')}` })}
        domId="project_name"
        autoFocus
        required
        label={`${t('saveProjectDialog.projectNameLabel')}`}
        addClass={styles['text-field']}
      />
    </form>
  );
};
