import { useCallback } from 'react';
import { useCardContext } from '../context/card-context';
import { useUserZonesValidation } from '../views/editor/hooks';
import { useHandleProjectDone } from '../views/editor/hooks/useHandleProjectDone';
import { useOrchestrateActions } from '../views/editor/hooks/useOrchestrateActions';
import { useFeatureFlags } from './useFeatureFlags';
import useIsCanvasEmpty from './useIsCanvasEmpty';
import { useIsOneToMany } from './useIsOneToMany';

export const useHandleEditingDone = () => {
  const { PREVIEW_STEP_TOGGLE = false } = useFeatureFlags();

  const { cardDispatch } = useCardContext();

  const isCanvasEmpty = useIsCanvasEmpty();
  const { skipValidation, validateUserZones, uneditedZones } = useUserZonesValidation();
  const isOneToMany = useIsOneToMany();

  const handleProjectDone = useHandleProjectDone(skipValidation, validateUserZones, uneditedZones, isOneToMany);
  const { orchestrateActions } = useOrchestrateActions();

  const handleEditingDone = useCallback(() => {
    if (PREVIEW_STEP_TOGGLE) {
      handleProjectDone();
    } else {
      orchestrateActions();
    }
  }, [isCanvasEmpty, PREVIEW_STEP_TOGGLE, handleProjectDone, orchestrateActions, cardDispatch]);

  return handleEditingDone;
};
