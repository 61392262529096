import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useAppContext } from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { useCropContext } from '../../../context/crop-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useFeatureFlags } from '../../../hooks';
import { useEmailOrderProcess } from '../../../hooks/useEmailOrderProcess';
import { useHandleEditingDone } from '../../../hooks/useHandleEditingDone';
import { useHandleIndexChange } from '../../../hooks/useHandleIndexChange';
import { getCardFaceLabel } from '../../../utils';
import { useUserZonesValidation } from './useUserZonesValidation';

export const useEditorNavigation = () => {
  const { t } = useTranslation();
  const [beforeEditingFaceJson, setBeforeEditingFaceJson] = useState<string | null>(null);
  const { PREVIEW_STEP_TOGGLE = false } = useFeatureFlags();
  const { isSavingCroppedImage } = useCropContext();

  const { cardState } = useCardContext();
  const {
    appState: { cardFacesLoaded },
  } = useAppContext();

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const { isSendingEmail } = useEmailOrderProcess();
  const { pageNavigationButtonClicked } = useAnalyticsContext();
  const { validateUserZones } = useUserZonesValidation();

  const slideIndex = cardState.slideCardIndex;

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );
  const handleEditingDone = useHandleEditingDone();
  const handleIndexChange = useHandleIndexChange(beforeEditingFaceJson, true);

  const isOnLastFace = slideIndex === editableFaces.length - 1;

  const handleIncrementSlideIndex = () => {
    pageNavigationButtonClicked.current = 'Edit Button';
    const newActiveCardIndex = editableFaces[slideIndex + 1].faceNumber - 1;
    handleIndexChange(slideIndex + 1, newActiveCardIndex);
  };

  const getNextButtonText = () => {
    const nextFace = editableFaces[slideIndex + 1];

    if (nextFace) {
      return t('editorView.nextFace', { face: getCardFaceLabel(nextFace.type) });
    }

    return t('editorView.lastFace');
  };

  const previewProps = {
    click: isOnLastFace ? handleEditingDone : handleIncrementSlideIndex,
    children: getNextButtonText(),
    disabled:
      isOnLastFace && initializedData?.project_type_code === 'S' ? !validateUserZones() || isSavingCroppedImage : false,
  };

  const withoutPreviewProps = {
    click: handleEditingDone,
    children: getNextButtonText(),
    disabled: isSendingEmail,
  };

  const buttonProps = PREVIEW_STEP_TOGGLE ? previewProps : withoutPreviewProps;

  useEffect(() => {
    // If cardFaces haven't loaded yet, exit the code
    if (editableFaces.length <= 0) {
      return;
    }

    // Check if frontFace canvas has loaded
    const isFrontFaceLoaded = editableFaces[0]?.canvas !== undefined;

    if (!beforeEditingFaceJson && isFrontFaceLoaded && cardFacesLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[0].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
    if (beforeEditingFaceJson && isFrontFaceLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[`${slideIndex}`].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
  }, [slideIndex, editableFaces, cardFacesLoaded]);

  return { buttonProps, getNextButtonText, handleIncrementSlideIndex };
};
