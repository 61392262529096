import React from 'react';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { ImageResponse, RecentBasket } from '../../global-types';
import { DialogType } from '../../global-types/dialog';
import { PreviewImage } from '../../global-types/images';
import { TransformedPersonalizationData } from '../../utils/helper-settings';

export type AppToasterState = {
  isOpen: boolean;
  title: string;
  variant?: ToastVariants;
  children: string;
};

export type AddToCartBodyParam = {
  pid: string;
  quantity: string;
  projectID: string;
  fulfillmentProductTypeCode: string; // Sign and Send --- probably returns an error at the moment
  shippingOptionCode: string; // Only S - Standard for now
  firstName: string;
  lastName: string;
  addressLine1: string;
  city: string;
  stateCode?: string;
  zip: string;
  addressLine2: string;
  companyName: string;
  countryCode: string;
  scanCode: string;
  thumbnailImageURL: string;
};

export type CartUtility = {
  loading: boolean;
  recentBasket: RecentBasket;
  basketError: boolean;
  addToCart: (
    requestBodyParam: any,
    closeModalOverrideParam: any,
    keepShoppingOverrideParam: any,
    cartOptionsParam?: any,
  ) => void;
  basket: any;
  setBasket: React.Dispatch<any>;
  closeModalOverride: any;
  keepShoppingOverride: any;
};

export enum ImageEditDrawerModes {
  UserImage = 'user-image', // the same as CanvasDataTypes.UserText
  StickerImage = 'sticker-image', // the same as CanvasDataTypes.StickerImage
}

export type DrawersState = {
  isTextDrawerOpen: boolean;
  isAlignmentDrawerOpen: boolean;
  isFontDrawerOpen: boolean;
  isSizeDrawerOpen: boolean;
  isImageUploadDrawerOpen: boolean;
  isImageUploadToPhotoZoneOpen: boolean;
  isImageEditDrawerOpen: boolean;
  imageEditDrawerMode: ImageEditDrawerModes;
  isWamDrawerOpen: boolean;
  isRotationDrawerOpen: boolean;
  isColorDrawerOpen: boolean;
  isColorPalleteDrawerOpen: boolean;
  isOrderDrawerOpen: boolean;
  isScaleDrawerOpen: boolean;
  isStickerPickDrawerOpen: boolean;
};

export type AppContextState = DrawersState & {
  isMobileApp: boolean | null;
  isTabletApp: boolean | null;
  mobileAppToken: string;
  isSidePanelVisible: boolean;
  loadedComponent: null; // it refers to what's in the sidePanel parent component
  loadedComponentId: string;
  loader: { isLoading: boolean; title: string };
  editState: null;
  editMode: null;
  useMock: boolean;
  renderFontsOnServer: boolean;
  hasMouse: boolean;
  // hasMouse: boolean,
  isIE: boolean; // currently not in use
  isMember: boolean; // default needs to be boolean
  loginTemplate: null;
  action: string;
  isProjectSaved: boolean;
  isPhotoZoneSelected: boolean;
  isSystemErrorOpen: boolean;
  isRedirectDialogOpen: boolean;
  redirectUrl: string;
  errorMessages: string[];
  isCardCaption: boolean;
  isDeleteConfirmationDialogOpen: boolean;
  isResetConfirmationDialogOpen: boolean;
  isClearConfirmationDialogOpen: boolean;
  localizationObj: unknown[];
  localizationsLoaded: boolean;
  personalizationData: TransformedPersonalizationData[];
  photoTrayImages: ImageResponse[];
  objectsAdded: {
    firstPage: {
      texts: number;
      images: number;
      decorations: number;
    };
    secondPage: {
      texts: number;
      images: number;
      decorations: number;
    };
  };
  // TODO: When shared props from main are passed to POD App, store addToCartFunction in cartUtility
  cartUtility: CartUtility | null;
  accountId: string;
  sentiments: unknown;
  exitUtility: null;
  toaster: AppToasterState;
  moreColorsAdded: string[];
  exitUrl: string;
  isSendToMe?: boolean;
  triggeringElementRef: HTMLElement | HTMLButtonElement | HTMLInputElement | null;
  csrfToken: string;
  productQuantity: string;
  cardFacesLoaded: boolean;
  isSaving: boolean;
  isSavingSaveButton: boolean;
  signInDialogOpen: boolean;
  dialogType: DialogType | null;
  previewImages: PreviewImage[];
  // jwt: string;
};

// Provider
export type AppProviderProps = {
  children: React.ReactNode;
  shared?: any;
};

// Actions
export enum actionTypes {
  SET_IS_TEXT_DRAWER_OPEN = 'SET_IS_TEXT_DRAWER_OPEN',
  SET_IS_ALIGNMENT_DRAWER_OPEN = 'SET_IS_ALIGNMENT_DRAWER',
  SET_IS_SIZE_DRAWER_OPEN = 'SET_IS_SIZE_DRAWER_OPEN',
  SET_IS_FONT_DRAWER_OPEN = 'SET_IS_FONT_DRAWER_OPEN',
  SET_IS_TAM_DRAWER_OPEN = 'SET_IS_TAM_DRAWER_OPEN',
  SET_IS_IMAGE_UPLOAD_DRAWER_OPEN = 'SET_IS_IMAGE_UPLOAD_DRAWER_OPEN',
  SET_IS_IMAGE_UPLOAD_TO_PHOTOZONE_DRAWER_OPEN = 'SET_IS_IMAGE_UPLOAD_TO_PHOTOZONE_DRAWER_OPEN',
  SET_IS_IMAGE_EDIT_DRAWER_OPEN = 'SET_IS_IMAGE_EDIT_DRAWER_OPEN',
  SET_IS_STICKER_PICK_DRAWER_OPEN = 'SET_IS_STICKER_PICK_DRAWER_OPEN',
  SET_IS_ORDER_DRAWER_OPEN = 'SET_IS_ORDER_DRAWER_OPEN',
  SET_IS_WAM_DRAWER_OPEN = 'SET_IS_WAM_DRAWER_OPEN',
  SET_IS_ROTATION_DRAWER_OPEN = 'SET_IS_ROTATION_DRAWER_OPEN',
  SET_IS_SCALE_DRAWER_OPEN = 'SET_IS_SCALE_DRAWER_OPEN',
  SET_ALL_DRAWERS_CLOSED = 'SET_ALL_DRAWERS_CLOSED',
  SET_IS_TOASTER_OPEN = 'SET_IS_TOASTER_OPEN',
  SET_IS_TOASTER_CLOSED = 'SET_IS_TOASTER_CLOSED',
  SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN',
  HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN',
  SET_IS_DELETE_CONFIRMATION_DIALOG_OPEN = 'SET_IS_DELETE_CONFIRMATION_DIALOG_OPEN',
  SET_IS_CLEAR_CONFIRMATION_DIALOG_OPEN = 'SET_IS_CLEAR_CONFIRMATION_DIALOG_OPEN',
  SET_IS_RESET_CONFIRMATION_DIALOG_OPEN = 'SET_IS_RESET_CONFIRMATION_DIALOG_OPEN',
  SET_IS_DELETE_CONFIRMATION_DIALOG_CLOSED = 'SET_IS_DELETE_CONFIRMATION_DIALOG_CLOSED',
  SET_IS_CLEAR_CONFIRMATION_DIALOG_CLOSED = 'SET_IS_CLEAR_CONFIRMATION_DIALOG_CLOSED',
  SET_IS_RESET_CONFIRMATION_DIALOG_CLOSED = 'SET_IS_RESET_CONFIRMATION_DIALOG_CLOSED',
  SET_IS_COLOR_DRAWER_OPEN = 'SET_IS_COLOR_DRAWER_OPEN',
  SET_IS_SYSTEM_ERROR_OPEN = 'SET_IS_SYSTEM_ERROR_OPEN',
  SET_IS_REDIRECT_DIALOG_OPEN = 'SET_IS_REDIRECT_DIALOG_OPEN',
  SET_IS_COLOR_PALLETE_DRAWER_OPEN = 'SET_IS_COLOR_PALLETE_DRAWER_OPEN',
  SET_IS_PROJECT_SAVED = 'SET_IS_PROJECT_SAVED',
  SET_IS_PHOTO_TEXT_ZONE_SELECTED = 'SET_IS_PHOTO_TEXT_ZONE_SELECTED',
  SET_CART_UTILITY = 'SET_CART_UTILITY',
  SET_PERSONALIZATION_DATA = 'SAVE_PERSONALIZATION_DATA',
  ADD_COLOR_TO_MORE_COLORS_ADDED = 'ADD_COLOR_TO_MORE_COLORS_ADDED',
  SET_EXIT_URL = 'SET_EXIT_URL',
  SET_SEND_TO_ME = 'SET_SEND_TO_ME',
  SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES',
  ADD_PHOTO_TRAY_IMAGES = 'ADD_PHOTO_TRAY_IMAGES',
  DELETE_PHOTO_TRAY_IMAGE = 'DELETE_PHOTO_TRAY_IMAGES',
  SET_TRIGGERING_ELEMENT_REF = 'SET_TRIGGERING_ELEMENT_REF',
  SET_CSRF_TOKEN = 'SET_CSRF_TOKEN',
  SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY',
  SET_CARD_FACES_LOADED = 'SET_CARD_FACES_LOADED',
  SET_IS_SAVING = 'SET_IS_SAVING',
  SET_IS_SAVING_SAVE_BUTTON = 'SET_IS_SAVING_SAVE_BUTTON',
  SET_SIGN_IN_DIALOG_OPEN = 'SET_SIGN_IN_DIALOG_OPEN',
  STORE_PREVIEW_IMAGES = 'STORE_PREVIEW_IMAGES',
  SET_DIALOG_TYPE = 'SET_DIALOG_TYPE',
  // SET_JWT = 'SET_JWT',
}

export type AppAction =
  | SetIsTextDrawerOpenAction
  | SetIsAlignmentDrawerOpen
  | SetIsOrderDrawerOpen
  | SetIsSizeDrawerOpen
  | SetIsFontDrawerOpen
  | SetAllDrawersClosed
  | SetIsRotationDrawerOpen
  | SetIsScaleDrawerOpen
  | SetIsToasterOpen
  | SetIsToasterClosed
  | SetIsImageUploadDrawerOpen
  | SetIsImageUploadToPhotoZoneDrawerOpen
  | SetIsStickerPickDrawerOpen
  | SetIsImageEditDrawerOpen
  | SetIsProjectSaved
  | SetIsWamDrawerOpen
  | SetIsSystemErrorOpen
  | SetIsRedirectDialogOpen
  | SetAllDrawersClosed
  | SetErrorMessages
  | ShowLoadingScreen
  | HideLoadingScreen
  | setIsDeleteConfirmationDialogClosed
  | setIsDeleteConfirmationDialogOpen
  | setIsClearConfirmationDialogClosed
  | setIsClearConfirmationDialogOpen
  | setIsResetConfirmationDialogClosed
  | setIsResetConfirmationDialogOpen
  | setIsColorDrawerOpen
  | setIsColorPalleteDrawerOpen
  | setCartUtility
  | AddColorToMoreColorsAdded
  | setExitUrl
  | setIsSendToMe
  | SetPersonalizationDataAction
  | AddPhotoTrayImages
  | DeletePhotoTrayImage
  | SetTriggeringElementRef
  | SetCsrfToken
  | SetProductQuantity
  | SetCardFacesLoaded
  | SetIsSaving
  | SetIsSavingSaveButton
  | SetSignInDialogOpen
  | StorePreviewImages
  | SetDialogType;
// | SetJwt;

export type SetIsTextDrawerOpenAction = {
  type: actionTypes.SET_IS_TEXT_DRAWER_OPEN;
};

export type SetIsAlignmentDrawerOpen = {
  type: actionTypes.SET_IS_ALIGNMENT_DRAWER_OPEN;
};

export type SetIsSizeDrawerOpen = {
  type: actionTypes.SET_IS_SIZE_DRAWER_OPEN;
};

export type SetIsFontDrawerOpen = {
  type: actionTypes.SET_IS_FONT_DRAWER_OPEN;
};

export type SetIsProjectSaved = {
  type: actionTypes.SET_IS_PROJECT_SAVED;
  payload: boolean;
};

export type SetIsImageUploadDrawerOpen = {
  type: actionTypes.SET_IS_IMAGE_UPLOAD_DRAWER_OPEN;
};

export type SetIsImageUploadToPhotoZoneDrawerOpen = {
  type: actionTypes.SET_IS_IMAGE_UPLOAD_TO_PHOTOZONE_DRAWER_OPEN;
};

export type SetIsStickerPickDrawerOpen = {
  type: actionTypes.SET_IS_STICKER_PICK_DRAWER_OPEN;
};

export type SetIsImageEditDrawerOpen = {
  type: actionTypes.SET_IS_IMAGE_EDIT_DRAWER_OPEN;
  payload: ImageEditDrawerModes;
};

export type SetIsOrderDrawerOpen = {
  type: actionTypes.SET_IS_ORDER_DRAWER_OPEN;
};

export type SetIsWamDrawerOpen = {
  type: actionTypes.SET_IS_WAM_DRAWER_OPEN;
};

export type SetIsRotationDrawerOpen = {
  type: actionTypes.SET_IS_ROTATION_DRAWER_OPEN;
};

export type SetIsScaleDrawerOpen = {
  type: actionTypes.SET_IS_SCALE_DRAWER_OPEN;
};

export type SetErrorMessages = {
  type: actionTypes.SET_ERROR_MESSAGES;
  payload: string[];
};

export type SetAllDrawersClosed = {
  type: actionTypes.SET_ALL_DRAWERS_CLOSED;
};

export type SetIsToasterOpen = {
  type: actionTypes.SET_IS_TOASTER_OPEN;
  payload: Omit<AppToasterState, 'isOpen'>;
};

export type SetIsToasterClosed = {
  type: actionTypes.SET_IS_TOASTER_CLOSED;
};

export type SetIsSystemErrorOpen = {
  type: actionTypes.SET_IS_SYSTEM_ERROR_OPEN;
  payload: boolean;
};

export type SetIsRedirectDialogOpen = {
  type: actionTypes.SET_IS_REDIRECT_DIALOG_OPEN;
  payload: { isDialogOpen: boolean; redirectUrl: string };
};

export type ShowLoadingScreen = {
  type: actionTypes.SHOW_LOADING_SCREEN;
  payload: string;
};

export type HideLoadingScreen = {
  type: actionTypes.HIDE_LOADING_SCREEN;
};

export type setIsDeleteConfirmationDialogOpen = {
  type: actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_OPEN;
  payload: DialogType;
};

export type setIsDeleteConfirmationDialogClosed = {
  type: actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_CLOSED;
};

export type setIsResetConfirmationDialogOpen = {
  type: actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_OPEN;
};

export type setIsResetConfirmationDialogClosed = {
  type: actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_CLOSED;
};

export type setIsClearConfirmationDialogOpen = {
  type: actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_OPEN;
};

export type setIsClearConfirmationDialogClosed = {
  type: actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_CLOSED;
};

export type setIsColorDrawerOpen = {
  type: actionTypes.SET_IS_COLOR_DRAWER_OPEN;
};

export type setIsColorPalleteDrawerOpen = {
  type: actionTypes.SET_IS_COLOR_PALLETE_DRAWER_OPEN;
};

export type setCartUtility = {
  type: actionTypes.SET_CART_UTILITY;
  payload: CartUtility;
};

export type AddColorToMoreColorsAdded = {
  type: actionTypes.ADD_COLOR_TO_MORE_COLORS_ADDED;
  payload: string;
};

export type setExitUrl = {
  type: actionTypes.SET_EXIT_URL;
  payload: string;
};

export type setIsSendToMe = {
  type: actionTypes.SET_SEND_TO_ME;
  payload: boolean;
};

export type SetPersonalizationDataAction = {
  type: actionTypes.SET_PERSONALIZATION_DATA;
  payload: TransformedPersonalizationData[];
};

export type AddPhotoTrayImages = {
  type: actionTypes.ADD_PHOTO_TRAY_IMAGES;
  payload: ImageResponse[];
};

export type DeletePhotoTrayImage = {
  type: actionTypes.DELETE_PHOTO_TRAY_IMAGE;
  payload: ImageResponse['photo_tray_image_id'];
};

export type SetTriggeringElementRef = {
  type: actionTypes.SET_TRIGGERING_ELEMENT_REF;
  payload: HTMLElement | HTMLInputElement | HTMLButtonElement;
};

export type SetCsrfToken = {
  type: actionTypes.SET_CSRF_TOKEN;
  payload: string;
};

export type SetProductQuantity = {
  type: actionTypes.SET_PRODUCT_QUANTITY;
  payload: string;
};

export type SetCardFacesLoaded = {
  type: actionTypes.SET_CARD_FACES_LOADED;
  payload: boolean;
};

export type SetIsSaving = {
  type: actionTypes.SET_IS_SAVING;
  payload: boolean;
};

export type SetIsSavingSaveButton = {
  type: actionTypes.SET_IS_SAVING_SAVE_BUTTON;
  payload: boolean;
};

export type SetSignInDialogOpen = {
  type: actionTypes.SET_SIGN_IN_DIALOG_OPEN;
  payload: boolean;
};

export type StorePreviewImages = {
  type: actionTypes.STORE_PREVIEW_IMAGES;
  payload: PreviewImage[];
};

export type SetDialogType = {
  type: actionTypes.SET_DIALOG_TYPE;
  payload: DialogType;
};

// export type SetJwt = {
//   type: actionTypes.SET_JWT;
//   payload: string;
// };

export type Dispatch = (action: AppAction) => void;

export type CreateContextProps = { appState: AppContextState; appDispatch: Dispatch } | undefined;
