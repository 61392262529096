import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hallmark/web.core.buttons.button';
import { IconButton, IconButtonVariants } from '@hallmark/web.core.buttons.icon-button';
import { IconNames, IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { InlineSVG, HallmarkCrown } from '@hallmark/web.core.display.inline-svg';
import { RegionalCodesList } from '../../../global-types';
import { useFeatureFlags, useIsOneToMany, useSaveProjectDialog } from '../../../hooks';
import { config } from '../../../regional-config';
import { isLoggedIn } from '../../../utils';
import { QuantityLink } from '../../quantity-link';
import { SaveProjectDialog } from '../../save-project-dialog';
import { SaveProjectLink } from '../../save-project-link';
import { USHeaderLayoutProps } from '../print-on-demand-header-types';
import styles from '../print-on-demand-header.module.scss';

const USHeaderLayout = ({ onCloseButtonClick, onCrownIconClick, classes, closeButtonRef }: USHeaderLayoutProps) => {
  const { t } = useTranslation();
  // TODO: This should be re-added once the project summary is implemented for 1:1 cards (PRCM-3032)
  const showProjectSummary = useIsOneToMany();
  const { SAVED_PROJECTS_LINK } = useFeatureFlags();
  const {
    isSaveProjectDialogOpen,
    isAbandoningProject,
    onCloseDialog,
    onSignIn,
    onAbandonProject,
    onSaveProject,
    isExitFromXButton,
  } = useSaveProjectDialog();

  return (
    <div className={classes}>
      {showProjectSummary ? <QuantityLink /> : <div className={styles['quantity-link-placeholder']} />}
      <Button click={onCrownIconClick} addClass={styles.crown} testId="crown-icon-button" tabIndex={0}>
        <InlineSVG
          path={`${HallmarkCrown}`}
          title={`${t('header.hallmarkCrownLogo')}`}
          description={`${t('header.hallmarkCrownLogo')}`}
          role="img"
          svgColor={config?.header?.svgColor || IconButtonBrandColors.Purple}
        />
      </Button>

      <div className={`${styles['save-project']}`}>
        {SAVED_PROJECTS_LINK ? <SaveProjectLink /> : <div className={styles['save-project']} />}

        <IconButton
          ariaLabel={`${t('header.close')}`}
          testId="close-icon-button"
          icon={IconNames.Menuclose}
          addClass={styles['close-button']}
          variant={IconButtonVariants.Simple}
          size={28}
          ref={closeButtonRef}
          click={onCloseButtonClick}
          altText={`${t('header.closeButton')}`}
        />
      </div>

      {isSaveProjectDialogOpen && (
        <SaveProjectDialog
          isOpen={isSaveProjectDialogOpen}
          close={onCloseDialog}
          isLoggedIn={isLoggedIn()}
          isAbandoningProject={isAbandoningProject}
          onSaveProject={(data) => onSaveProject(data, isExitFromXButton)}
          onSignIn={onSignIn}
          onAbandonProject={onAbandonProject}
          theme={config?.header?.saveDialogTheme || RegionalCodesList.us}
        />
      )}
    </div>
  );
};

export default USHeaderLayout;
