import { useCardContext } from '../context/card-context';
import { CardFaceData } from '../global-types';

export const useCardFaceIsEditable = (): Pick<CardFaceData, 'isEditable' | 'canAddPhoto' | 'canAddText' | 'type'> => {
  const {
    cardState: { cardFacesList, activeCardIndex },
  } = useCardContext();
  const face = cardFacesList[`${activeCardIndex}`];

  if (!face) {
    return {
      isEditable: false,
      canAddPhoto: false,
      canAddText: false,
      type: '',
    };
  }
  const { canAddPhoto, canAddText, isEditable, type } = face;
  return { canAddPhoto, canAddText, isEditable, type };
};
