import { useLocation } from 'react-router-dom';
import { useInitializationDataContext } from '../context/data-context';
import { RegionalCodesList } from '../global-types';
import { CardType } from '../global-types/card';
import { EnvironmentsType } from '../global-types/feature-flags';
import { useIsOneToMany } from './useIsOneToMany';

type FeatureFlags = {
  SAVED_PROJECTS: boolean;
  ENVELOPE_SELECTION: boolean;
  S3_PHOTO_UPLOAD: boolean;
  DIGITAL_GREETINGS: boolean;
  SAVED_PROJECTS_LINK: boolean;
  INSIDE_PLACEHOLDER_TEXT: boolean;
  DYNAMIC_TEXBOXES: boolean;
  DYNAMIC_TEXBOX_DRAWER: boolean;
  DISPLAY_ZOOM_DEV: boolean;
  SAS_TOOLBAR_TOGGLE: boolean;
  PREVIEW_STEP_TOGGLE: boolean;
  IS_URL_IMAGE_ENABLED: boolean;
  IS_FE_CROPPING_ENABLED: boolean;
  GENERATE_FE_PREVIEW: boolean;
  CANVAS_JSON_PREVIEW: boolean;
  GENERATE_FE_PREVIEWS?: boolean;
};

const defaultFlags = {
  SAVED_PROJECTS: false,
  ENVELOPE_SELECTION: false,
  S3_PHOTO_UPLOAD: false,
  DIGITAL_GREETINGS: false,
  SAVED_PROJECTS_LINK: false,
  INSIDE_PLACEHOLDER_TEXT: false,
  DYNAMIC_TEXBOXES: false,
  IS_FE_CROPPING_ENABLED: false,
  DYNAMIC_TEXBOX_DRAWER: false,
  DISPLAY_ZOOM_DEV: false,
  SAS_TOOLBAR_TOGGLE: false,
  PREVIEW_STEP_TOGGLE: false,
  IS_URL_IMAGE_ENABLED: false,
  GENERATE_FE_PREVIEW: false,
  CANVAS_JSON_PREVIEW: false,
};

export const useFeatureFlags = (): FeatureFlags => {
  const {
    initializedDataState: { data },
  } = useInitializationDataContext();
  const isOneToMany = useIsOneToMany();
  const { pathname } = useLocation();

  const enabledEnvironments = (environments: EnvironmentsType[]): boolean => {
    const currentEnv = process.env.REACT_APP_CURRENT_ENV ?? null;
    const currentTheme = process.env.REACT_APP_THEME ?? null;

    if (!currentEnv || !currentTheme) {
      return false;
    }
    const currentEnvCode = `${currentTheme}-${currentEnv}`;
    // Checks for current environment in list of environments
    const matchedEnvironment = environments.filter((environment) => environment === currentEnvCode);
    // If no match is found
    if (matchedEnvironment.length <= 0) {
      return false;
    }
    // Get regional code of matched environment (for example, 'us' from 'us-dev');
    const regionalCode = matchedEnvironment[0].split('-')[0];

    if (regionalCode) {
      const regionsList = {
        us: regionalCode === 'us',
        uk: regionalCode === 'uk',
        ce: regionalCode === 'ce',
        all: regionalCode === 'all',
      };
      return regionsList[regionalCode as RegionalCodesList] || false;
    } else {
      return false;
    }
  };

  const enabledRoutes = (routes: string[]): boolean => {
    if (!pathname) return false;

    const matchedRoute = routes.filter((route) => pathname.includes(route));

    if (matchedRoute.length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Flags
   */

  // Should only show in US dev
  const DISPLAY_ZOOM_DEV = enabledEnvironments([EnvironmentsType.UsStage]); // Enable on dev for local development

  // Should only show in US dev and stage
  const SAVED_PROJECTS = enabledEnvironments([
    EnvironmentsType.CeDev,
    EnvironmentsType.CeStage,
    EnvironmentsType.CeProd,
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
  ]);

  /** Enable hasControls when adding editable texts if the DYNAMIC_TEXBOXES feature is enabled UsDev, UsStage */
  const DYNAMIC_TEXBOXES = enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UkDev]);

  const SAVED_PROJECTS_LINK = enabledRoutes(['/edit/']) && SAVED_PROJECTS;

  // Should show on US dev and stage, where project_type_code is digital greeting
  const DIGITAL_GREETINGS =
    enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UsStage]) &&
    (data?.project_type_code === CardType.DG || data?.is_digital_fulfillment === true);

  // should show on dev US, Stage and Prod
  const DYNAMIC_TEXBOX_DRAWER = enabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
    EnvironmentsType.UkProd,
  ]);

  // feature for hiding and showing the toolbar based on if a S&S user zome is selected or not (MIMIR-3137)
  const SAS_TOOLBAR_TOGGLE = enabledEnvironments([]);

  const PREVIEW_STEP_TOGGLE = enabledEnvironments([
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
    EnvironmentsType.UkProd,
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
  ]);

  // Only show in US dev and stage, with POD one to many cards.
  const ENVELOPE_SELECTION =
    enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UsStage, EnvironmentsType.UsProd]) &&
    isOneToMany &&
    data?.project_type_code === CardType.POD;

  const INSIDE_PLACEHOLDER_TEXT = enabledEnvironments([
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
    EnvironmentsType.UkProd,
  ]);

  // Enables and disables the direct-to-s3 image upload endpoint (MIMIR-3024)
  const S3_PHOTO_UPLOAD = enabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.CeDev,
    EnvironmentsType.CeStage,
    EnvironmentsType.CeProd,
  ]);

  // Enables or disables using the url from 'image_url' for the image added to the card in the thumbnail component (MIMIR-3180)
  const IS_URL_IMAGE_ENABLED = enabledEnvironments([
    EnvironmentsType.UsStage, // Change Flag to Dev when working locally
    EnvironmentsType.UkDev,
    EnvironmentsType.CeDev,
    EnvironmentsType.CeStage,
  ]);

  // Enables FE Cropping for us (added in MIMIR-
  const IS_FE_CROPPING_ENABLED = enabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.CeDev,
    EnvironmentsType.CeStage,
  ]);

  // Whether app should generate previews itself. If false, will utilize preview endpoint
  const GENERATE_FE_PREVIEWS = enabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.CeDev,
    EnvironmentsType.CeStage,
  ]);

  // Whether app should generate FE previews using canvasJson. Temporary to test previews, can remove after FE previews is tested and released
  // If GENERATE_FE_PREVIEWS is true and CANVAS_JSON_PREVIEWS is false, FE will use printJson to generate previews
  const CANVAS_JSON_PREVIEW = enabledEnvironments([]);

  return {
    ...defaultFlags,
    SAVED_PROJECTS,
    ENVELOPE_SELECTION,
    S3_PHOTO_UPLOAD,
    DIGITAL_GREETINGS,
    SAVED_PROJECTS_LINK,
    INSIDE_PLACEHOLDER_TEXT,
    DYNAMIC_TEXBOXES,
    IS_FE_CROPPING_ENABLED,
    DYNAMIC_TEXBOX_DRAWER,
    DISPLAY_ZOOM_DEV,
    SAS_TOOLBAR_TOGGLE,
    PREVIEW_STEP_TOGGLE,
    IS_URL_IMAGE_ENABLED,
    GENERATE_FE_PREVIEWS,
    CANVAS_JSON_PREVIEW,
  };
};
