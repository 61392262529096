import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { setIsTextDrawerOpen, useAppContext } from '../../../context/app-context';
import { CustomFabricObject } from '../../../global-types/canvas';
import { useActiveCanvas, useCanvasImageHandlers } from '../../../hooks';
import { config } from '../../../regional-config';
import { HeaderContent, FooterContent } from './fragments';
import { RotateDrawerProps } from './rotate-drawer-types';
import styles from './rotate-drawer.module.scss';

export const RotateDrawer = ({ isOpen, onChangeCallback }: RotateDrawerProps): React.ReactElement => {
  const { t } = useTranslation();
  const canvas = useActiveCanvas();
  const { appDispatch } = useAppContext();
  const [initialAngle, setInitialAngle] = React.useState(canvas?.current?.getActiveObject()?.angle ?? 0);
  const { openImageEditDrawerByCanvasDataType } = useCanvasImageHandlers();

  useEffect(() => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
  }, [canvas?.current?.getActiveObject()?.angle]);

  const activeObject = canvas?.current?.getActiveObject();
  const currentAngle = activeObject?.angle ?? 0;

  const handleRotateOpenDrawer = () => {
    const activeObject = canvas?.current?.getActiveObject();

    const isEditImageDrawerOpened = openImageEditDrawerByCanvasDataType(activeObject?.data?.type);
    if (!isEditImageDrawerOpened) {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const onSubmitRotate = () => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
    handleRotateOpenDrawer();
  };

  const handleCancel = () => {
    const activeObject = canvas?.current?.getActiveObject() as CustomFabricObject;
    if (activeObject) {
      activeObject._setOriginToCenter();
      activeObject.set('angle', initialAngle);
      activeObject.fire('custom:updateBorders');
      activeObject._resetOrigin();
      canvas?.current?.renderAll();
    }
    handleRotateOpenDrawer();
  };

  return (
    <Drawer
      drawerPosition={DrawerPositions.Bottom}
      hideCloseButton={true}
      onClose={handleRotateOpenDrawer}
      isOpen={isOpen}
      addClass={styles['rotate-drawer']}
      headerContent={<HeaderContent onChangeCallback={onChangeCallback} initialValue={currentAngle} />}
      role="toolbar"
      showVeil={false}
      layoutVariant={config?.rotateDrawer?.layoutVariant || undefined}
    >
      <FooterContent onDrawerCancel={handleCancel} onDrawerApply={onSubmitRotate} />
      <Typography variant={TypographyVariants.SRT}>{t('rotateDrawer.rotateDrawer')}</Typography>
    </Drawer>
  );
};

RotateDrawer.defaultProps = {
  addClass: '',
};
