export const MAX_DEGREES = 360;

/**
 * Convert the rotation angle from range [0, 360] to [-180, 180].
 * getComputedRotation(190) >> -170
 *
 * @param rotation Rotation angle in degrees [0, 360]
 * @returns Rotation angle in the range of [-180, 180]
 */
export const getComputedRotation = (rotation: number): number =>
  rotation > MAX_DEGREES / 2 ? rotation - MAX_DEGREES : rotation;
