import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors, DialogContentType } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useProjectSummary } from '../../hooks';
import { AuthenticatedForm } from './fragments/authenticated-form';
import { AbandonProjectButton, CancelButton, SubmitButton } from './fragments/footer-buttons';
import { AuthenticatedFormData, DialogFooter, SaveProjectDialogProps } from './save-project-dialog-types';
import styles from './save-project-dialog.module.scss';

export const SaveProjectDialog = ({
  close,
  isLoggedIn,
  isOpen,
  isAbandoningProject,
  onSaveProject,
  onSignIn,
  onAbandonProject,
  theme,
}: SaveProjectDialogProps) => {
  const { t } = useTranslation();
  const { defaultProjectName } = useProjectSummary();

  // Get dialog title and description
  const unauthenticatedTitle = isAbandoningProject
    ? t('saveProjectDialog.abandonTitle')
    : t('saveProjectDialog.signInTitle');

  const unauthenticatedDescription = isAbandoningProject
    ? t('saveProjectDialog.abandonDescription')
    : t('saveProjectDialog.signInDescription');

  const title = isLoggedIn ? t('saveProjectDialog.saveProjectTitle') : unauthenticatedTitle;
  const description = isLoggedIn ? t('saveProjectDialog.saveProjectDescription') : unauthenticatedDescription;

  const saveProjectForm = useForm<AuthenticatedFormData>({
    values: { projectName: defaultProjectName },
    mode: 'onBlur',
  });

  const { handleSubmit: handleSaveProjectSubmit } = saveProjectForm;

  // * After OneSite we will enable the in-site sign in and we can uncomment the following comments. Do not delete this code
  // const signInForm = useForm<SignInFormData>({ mode: 'onBlur' });
  // const { handleSubmit: handleSignInSubmit } = signInForm;
  // const signInFormProps: SignInFormProps = {
  //   emailLabel: t('saveProjectDialog.emailLabel'),
  //   passwordLabel: t('saveProjectDialog.passwordLabel'),
  //   forgotPasswordLabel: t('saveProjectDialog.forgotPasswordLabel'),
  //   registerLinkLabel: t('saveProjectDialog.registerLinkLabel'),
  //   noAccountLabel: t('saveProjectDialog.noAccountLabel'),
  //   invalidEmailText: t('saveProjectDialog.invalidEmailText'),
  //   invalidPasswordText: t('saveProjectDialog.invalidPasswordText'),
  //   requiredEmailText: t('saveProjectDialog.requiredEmailText'),
  //   requiredPasswordText: t('saveProjectDialog.requiredPasswordText'),
  //   formMethods: signInForm,
  //   forgotPasswordLink: `${accountUrl}/#/forgotpassword`,
  //   registerLink: `${accountUrl}/#/signup`,
  //   addClass: styles.form,
  // };

  const dialogFooterProps: DialogFooter = {
    footerType: isAbandoningProject ? DialogFooterType.MultipleButtons : DialogFooterType.Cancel,
    actionButton: (
      <SubmitButton
        isLoggedIn={isLoggedIn}
        onSaveProject={handleSaveProjectSubmit(onSaveProject)}
        onSignIn={onSignIn}
      />
    ),
  };

  if (isAbandoningProject) {
    dialogFooterProps.secondaryActionButton = <AbandonProjectButton onAbandon={onAbandonProject} />;
  } else {
    dialogFooterProps.cancelButton = <CancelButton onCancel={close} />;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={title}
      accentIcon={IconNames.CardFront}
      accentIconColor={DialogBrandColors.White}
      type={DialogContentType.FeatureModal}
      addDialogWrapperClass={styles['dialog-wrapper']}
      footerType={dialogFooterProps.footerType}
      cancelButton={dialogFooterProps.cancelButton}
      actionButton={dialogFooterProps.actionButton}
      secondaryActionButton={dialogFooterProps.secondaryActionButton}
      locale={theme}
    >
      <>
        <Typography variant={TypographyVariants.Body}>{description}</Typography>
        {isLoggedIn ? (
          <AuthenticatedForm
            key={defaultProjectName}
            onSubmit={handleSaveProjectSubmit(onSaveProject)}
            formMethods={saveProjectForm}
          />
        ) : null}
      </>
    </Dialog>
  );
};
