import { useState } from 'react';
import { useAppContext, setIsSavingSaveButton } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useFeatureFlags, useSaveProject } from '../../../hooks';

export const useAutoSaveProject = () => {
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const { appDispatch } = useAppContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { saveProject } = useSaveProject();
  const { SAVED_PROJECTS } = useFeatureFlags();

  const autoSaveProject = async () => {
    if (SAVED_PROJECTS) {
      setIsSavingSaveButton(appDispatch, true);
      // Generate previews if we don't have any
      if (initializedData?.project_type_code !== 'S' && !previewGenerated) {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: true,
        });
        setPreviewGenerated(true);
      } else {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
        });
      }
      setIsSavingSaveButton(appDispatch, false);
    }
  };

  return { autoSaveProject };
};
