import { RefObject } from 'react';
import { CardFaceData } from '../../../global-types';
import { getCanvasJson } from '../../../utils/helper-settings/';
import { useAutoSaveProject } from './useAutoSaveProject';

export type OnCardFaceIndexChangeOptions = {
  activeCanvas: RefObject<fabric.Canvas> | undefined;
  editableFaces: CardFaceData[];
  slideIndex: number;
  beforeEditingFaceJson?: string | null | undefined;
  isLoggedIn: boolean;
};

/**
 * Hook to handle extra logic and save project if current face canvas was changed and user is logged in
 * @returns onCardFaceIndexChange function
 */
export const useOnCardFaceIndexChange = () => {
  const { autoSaveProject } = useAutoSaveProject();

  /**
   * Triggered when current card face index is changed by clicking on next button or card-select-bar thumbnail btn
   * discards active object from the current canvas and calls autoSaveProject if changes were made to canvas
   * @param options { activeCanvas, editableFaces, slideIndex, beforeEditingFaceJson, isLoggedIn }
   */
  const onCardFaceIndexChange = (options: OnCardFaceIndexChangeOptions) => {
    const { activeCanvas, editableFaces, slideIndex, beforeEditingFaceJson, isLoggedIn } = options;
    const activeObject = activeCanvas?.current?.getActiveObject();
    if (activeObject) {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }

    const afterEditingFaceJson = JSON.stringify(getCanvasJson(editableFaces[`${slideIndex}`]).objects);
    // If the face was edited we auto save the project
    if (afterEditingFaceJson !== beforeEditingFaceJson && isLoggedIn) {
      autoSaveProject();
    }
  };

  return { onCardFaceIndexChange };
};
