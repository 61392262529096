/* eslint-disable no-console */
import { useAppContext } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { EnvelopePreviewResponse } from '../../../global-types';
import { useIsDigitalGreeting, useIsOneToMany } from '../../../hooks';
import { getEnvelopePreview } from '../../../services';
import { envelopes } from '../../envelope/envelope.data';
import { getEnvelopePreviewBody } from '../utils';

export const useLoadPreviewOnFrontend = () => {
  const { initializedDataState } = useInitializationDataContext();
  const { isUS } = initializedDataState;
  const isDigitalGreetings = useIsDigitalGreeting();
  const {
    initializedDataState: { data: initializedData, addressData },
  } = useInitializationDataContext();
  const { appState } = useAppContext();
  const { previewImages } = appState;
  const isOneToMany = useIsOneToMany();
  const projectId = initializedData?.project_id || '';

  const getPreviews = async () => {
    if (isUS && !isDigitalGreetings) {
      const { recipient, sender } = addressData;
      console.log('recipient', recipient);
      console.log('sender', sender);
      if (!recipient && !sender) {
        // If no recipient/sender, add blank envelope to previewImages (1-to-many)
        previewImages.push({
          dataURL: envelopes[0].image,
          type: 'Envelope',
        });
      } else if (initializedData && ((recipient && sender) || (isOneToMany && sender))) {
        // If we have recipient/sender, or if it's 1-to-many and we have sender, send request to get envelope preview
        // TODO: We can replace this w/ FE envelope preview generation in the future, but not in scope of this MVP
        const envelopePreviewBody = getEnvelopePreviewBody(
          recipient,
          sender,
          initializedData.product.envelope_color,
          initializedData.variables.template_data.CardSize,
        );
        const envResponse: EnvelopePreviewResponse = await getEnvelopePreview(projectId, envelopePreviewBody);
        previewImages.push({
          dataURL: envResponse.data?.encoded_image as string,
          type: 'Envelope',
        });
      }
    }
    return previewImages;
  };

  return {
    getPreviews,
  };
};
