import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range, RangeVariants } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { useAnalyticsContext } from '../../../../context/analytics-context';
import { CustomFabricObject } from '../../../../global-types/canvas';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import { HeaderContentProps } from '../rotate-drawer-types';
import { getComputedRotation } from '../rotate-drawer-utils';
import styles from '../rotate-drawer.module.scss';

export const HeaderContent = ({ onChangeCallback, initialValue }: HeaderContentProps): React.ReactElement => {
  const additionalClass = config?.rotateDrawer?.rangeLabelSymbol ?? 'percent';
  const rangeName = 'RotateRange';
  const { updateEditFormats } = useAnalyticsContext();
  const { register, setValue, watch } = useForm({
    defaultValues: {
      [rangeName]: getComputedRotation(initialValue),
    },
  });
  const rangeValue = watch(rangeName);
  const canvas = useActiveCanvas();
  const { t } = useTranslation();
  const activeObject = useMemo(() => canvas?.current?.getActiveObject() as CustomFabricObject, [canvas?.current]);
  const handleRotation = useCallback(
    (options) => {
      const rotation = options.transform?.target.angle ?? 0;
      setValue(rangeName, getComputedRotation(rotation));
    },
    [activeObject],
  );

  useEffect(() => {
    if (activeObject) {
      activeObject.on('rotating', handleRotation);
    }

    return () => {
      activeObject?.off('rotating', handleRotation);
    };
  }, [activeObject]);

  const onChange = (valueInRange: number) => {
    if (activeObject) {
      activeObject._setOriginToCenter();
      activeObject.set('angle', valueInRange);
      activeObject.fire('custom:updateBorders');
      updateEditFormats({ rotate: valueInRange.toString() });
      activeObject._resetOrigin();
      canvas?.current?.renderAll();
      if (onChangeCallback) {
        onChangeCallback();
      }
      setValue(rangeName, valueInRange);
    }
  };

  return (
    <div className={styles['header-wrapper']}>
      <div className={styles['rotation-label']}>
        <Typography variant={config?.rotateDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('rotateDrawer.left')}
        </Typography>
        <Typography
          variant={config?.rotateDrawer?.rangeLabelVariant ?? TypographyVariants.InputText}
          color={config?.rotateDrawer?.rangeLabelColor ?? BrandColors.MediumGray}
          addClass={`${styles['value']} ${styles[additionalClass as string]}`}
        >{`${Math.round(rangeValue)}`}</Typography>
        <Typography variant={config?.rotateDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('rotateDrawer.right')}
        </Typography>
      </div>
      <Range
        domId={'drawer-range'}
        label={config?.rotateDrawer?.hasRangeLabel ? 'Rotate' : ''}
        min={-180}
        max={180}
        step={1}
        showValueInLabel={true}
        testId="rotate-drawer--range"
        tickCount={9}
        onChange={onChange}
        register={register(rangeName)}
        variant={config?.rotateDrawer?.rangeVariant as RangeVariants}
        enableTickInteraction={config?.rotateDrawer?.enableTickInteraction ?? false}
        initialValue={initialValue}
      />
    </div>
  );
};
